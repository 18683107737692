import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { InputLabel, MenuItem, Select } from "@mui/material";
import "./form.css";
import styled from "styled-components";
import logo from "../../assets/Hasan.webp";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}.
      <Link color="inherit" target="_blank" href="https://hasan-oezdag.com/">
        HASAN OZDAG ONLINESHOP
      </Link>
      <br />
      Developer By{" "}
      <Link
        color="inherit"
        target="_blank"
        href="https://www.phanomprofessionals.com/"
      >
        Phanom Professionals
      </Link>
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
export default function Form() {
  const [shape, setShape] = useState("Round");
  const [tier, setTier] = useState("Select Tier");
  const [diameter, setDiameter] = useState("");
  const [height, setHeight] = useState("");
  const [fillings, setFillings] = useState(["None"]);
  const [selectedCoating, setSelectedCoating] = useState("");
  const [showColorInput, setShowColorInput] = useState(false);
  const [selectwriting, setWriting] = useState("Topper");
  const [cutOption, setCutOption] = useState("");
  const [handwritingOption, setHandwritingOption] = useState("");
  const [handwritingTextField, setHandwritingTextField] = useState(""); // Add this state
  const [freeTextOption, setfreeTextOption] = useState("");
  const [formData, setFormData] = useState({
    productName: "Product Title",
    productPrice: "10.00",
    customerName: "John Doe",
    datetime: "",
    properties: {
      Shape: "Round",
      Tier: "Select Tier",
      Diameter: "",
      Height: "",
      SquareSize: "",
      HeartSize: "",
      FreeformText: "",
      ColorCoating: "",
      selectwriting: "Topper",
      cutOption: "",
      freeTextOption: "",
    },
    image: null,
  });

  //   Product Name
  const heightOptions = [
    { value: "Height 10cm", text: "Height 10 cm" },
    { value: "Height 12cm", text: "Height 12 cm" },
    { value: "Height 15cm", text: "Height 15 cm" },
  ];

  const diameterOptions = {
    "Tier 1": [
      { value: "Diameter 18cm", text: "Diameter 18 cm" },
      { value: "Diameter 20cm", text: "Diameter 20 cm" },
      { value: "Diameter 26cm", text: "Diameter 26 cm" },
    ],
    "Tier 2": [
      { value: "Diameter 22 and 15 cm", text: "Diameter 22 and 15 cm" },
      { value: "Diameter 26 and 17 cm", text: "Diameter 26 and 17 cm" },
      { value: "Diameter 30 and 20 cm", text: "Diameter 30 and 20 cm" },
    ],
    "Tier 3": [
      {
        value: "Diameter 30, 20, and 12 cm",
        text: "Diameter 30, 20, and 12 cm",
      },
      {
        value: "Diameter 35, 25, and 15 cm",
        text: "Diameter 35, 25, and 15 cm",
      },
      {
        value: "Diameter 40, 30, and 20 cm",
        text: "Diameter 40, 30, and 20 cm",
      },
    ],
    "Tier 4": [
      {
        value: "Diameter 40, 30, 20, and 12 cm",
        text: "Diameter 40, 30, 20, and 12 cm",
      },
      {
        value: "Diameter 45, 35, 25, and 15 cm",
        text: "Diameter 45, 35, 25, and 15 cm",
      },
    ],
    "Tier 5": [
      {
        value: "Diameter 50, 40, 30, 20, and 12 cm",
        text: "Diameter 50, 40, 30, 20, and 12 cm",
      },
    ],
    "Tier 6": [
      {
        value: "Diameter 60, 50, 40, 30, 20, and 12 cm",
        text: "Diameter 60, 50, 40, 30, 20, and 12 cm",
      },
    ],
    "Tier 7": [
      {
        value: "Diameter 70, 60, 50, 40, 30, 20, and 12 cm",
        text: "Diameter 70, 60, 50, 40, 30, 20, and 12 cm",
      },
    ],
  };

  const tier1HeightOptions = {
    "Diameter 18cm": [{ value: "Height 10cm", text: "Height 10 cm" }],
    "Diameter 20cm": [{ value: "Height 12cm", text: "Height 12 cm" }],
    "Diameter 26cm": [{ value: "Height 15cm", text: "Height 15 cm" }],
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePropertyChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      properties: {
        ...prevFormData.properties,
        [name]: value,
      },
    }));
  };

  const handleShapeChange = (e) => {
    const shapeValue = e.target.value;
    setShape(shapeValue);
    setTier("Select Tier");
    setDiameter("");
    setHeight("");
    handlePropertyChange(e);
  };

  const handleWritingChange = (event) => {
    setWriting(event.target.value);
  };

  const handleCutOptionChange = (event) => {
    setCutOption(event.target.value);
  };

  const handleHandwritingOptionChange = (event) => {
    setHandwritingOption(event.target.value);
  };

  const handleHandwritingfreetextOption = (event) => {
    setHandwritingTextField(event.target.value);
    handlePropertyChange(event);
  };

  const handlefreetextOptionChange = (event) => {
    setfreeTextOption(event.target.value);
    handlePropertyChange(event);
  };

  const handleTierChange = (e) => {
    const selectedTier = e.target.value;
    setTier(selectedTier);
    setDiameter("");
    setHeight("");

    if (selectedTier !== "Select Tier") {
      const defaultDiameter = diameterOptions[selectedTier][0].value;
      setDiameter(defaultDiameter);

      if (selectedTier === "Tier 1") {
        const defaultHeight = tier1HeightOptions[defaultDiameter][0].value;
        setHeight(defaultHeight);
      }
    }

    handlePropertyChange(e);
  };

  const handleDiameterChange = (e) => {
    const selectedDiameter = e.target.value;
    setDiameter(selectedDiameter);

    if (tier === "Tier 1") {
      const defaultHeight = tier1HeightOptions[selectedDiameter][0].value;
      setHeight(defaultHeight);
    }

    handlePropertyChange(e);
  };

  const handleHeightChange = (e) => {
    const selectedHeight = e.target.value;
    setHeight(selectedHeight);
    handlePropertyChange(e);
  };

  // Handel Coating

  const handleCoatingChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCoating(selectedValue);
    setShowColorInput(selectedValue === "Farbe des Überzugs");
    handlePropertyChange({
      target: { name: "ColorCoating", value: "" },
    });
  };

  // const handleFillingsChange = (e) => {
  //   const { value, checked } = e.target;
  //   setFillings((prevFillings) => {
  //     if (checked) {
  //       if (prevFillings.length < 2) {
  //         return [...prevFillings, value];
  //       } else {
  //         alert("You can select up to 2 fillings only.");
  //         return prevFillings;
  //       }
  //     } else {
  //       return prevFillings.filter((filling) => filling !== value);
  //     }
  //   });
  // };

  const handleFillingsChange = (e) => {
    const { value, checked } = e.target;
    setFillings((prevFillings) => {
      if (value === "None") {
        return checked ? ["None"] : [];
      } else {
        const updatedFillings = prevFillings.filter(
          (filling) => filling !== "None"
        );
        if (checked) {
          if (updatedFillings.length < 2) {
            return [...updatedFillings, value];
          } else {
            alert("You can select up to 2 fillings only.");
            return updatedFillings;
          }
        } else {
          const newFillings = updatedFillings.filter(
            (filling) => filling !== value
          );
          return newFillings.length === 0 ? ["None"] : newFillings;
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const properties = {
      Shape: shape,
      Tier: tier,
      Diameter: diameter,
      Height: height,
      SquareSize: "",
      HeartSize: "",
      FreeformText: "",
      ColorCoating: "",
      freeTextOption: freeTextOption,
      datetime: formData.properties.datetime, // Include datetime here
    };

    if (shape === "Round") {
      properties.Diameter = diameter;
      properties.Height = height;
    } else if (shape === "Square") {
      properties.SquareSize = formData.properties.SquareSize;
    } else if (shape === "Heart") {
      properties.HeartSize = formData.properties.HeartSize;
    } else if (shape === "Freeform") {
      properties.FreeformText = formData.properties.FreeformText;
    }

    if (selectedCoating === "Farbe des Überzugs") {
      properties.ColorCoating = formData.properties.ColorCoating;
    }
    if (selectwriting === "Free text") {
      properties.freeTextOption = freeTextOption;
    }

    const data = {
      ...formData,
      fillings,
      properties,
      selectedCoating,
      selectwriting,
      cutOption,
      handwritingOption,
      handwritingTextField,
      freeTextOption,
    };

    fetch("https://app.hasan-oezdag.com/api/user/register", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        alert("Draft order created successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to create draft order.");
      });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      properties: {
        ...prevFormData.properties,
        Shape: shape,
        Tier: tier,
        Diameter: diameter,
        Height: height,
      },
    }));
  }, [shape, tier, diameter, height]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm" className="formm_div">
        <DD>
          <CssBaseline />
          <Box
            sx={{
              margin: 4,
              display: "flex",
              flexDirection: "column",
              // alignItems:"center",
            }}
          >     <div className="main-logo-div">
               <Link color="inherit" target="_blank" href="https://hasan-oezdag.com/">
               <img className="logo-img" src={logo} alt="img" />
               </Link>
               </div>
              
        
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Cake Order Form
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="product-name"> Product Name</InputLabel>
                  <TextField
                    autoComplete="given-name"
                    name="productName"
                    required
                    fullWidth
                    value={formData.productName}
                    onChange={handleChange}
                    id="productName"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="product-price"> Product Price €</InputLabel>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    id="productPrice"
                    onChange={handleChange}
                    value={formData.productPrice}
                    name="productPrice"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="date">Abholtag & Abholzeit</InputLabel>
                <TextField
                  required
                  fullWidth
                  value={formData.properties.datetime}
                  type="datetime-local"
                  onChange={handlePropertyChange}
                  id="datetime"
                  name="datetime"
                  autoComplete="datetime"
                />
              </Grid>
              {/* Shape Select */}
              <Grid item xs={12} sm={6}>
                <InputLabel id="shape">Form</InputLabel>
                <Select
                  labelId="shape"
                  id="shape"
                  fullWidth
                  value={shape}
                  label="Shape"
                  name="Shape"
                  onChange={handleShapeChange}
                >
                  <MenuItem value="Round">Round</MenuItem>
                  <MenuItem value="Square">Square</MenuItem>
                  <MenuItem value="Heart">Heart</MenuItem>
                  <MenuItem value="Freeform">Freeform</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel id="tier">Select Etagen</InputLabel>
                <Select
                  labelId="tier"
                  id="tier"
                  fullWidth
                  value={tier}
                  label="Tier"
                  name="Tier"
                  onChange={handleTierChange}
                >
                  <MenuItem value="Select Tier">Select Etagen</MenuItem>
                  <MenuItem value="Tier 1">Tier 1</MenuItem>
                  <MenuItem value="Tier 2">Tier 2</MenuItem>
                  <MenuItem value="Tier 3">Tier 3</MenuItem>
                  <MenuItem value="Tier 4">Tier 4</MenuItem>
                  <MenuItem value="Tier 5">Tier 5</MenuItem>
                  <MenuItem value="Tier 6">Tier 6</MenuItem>
                  <MenuItem value="Tier 7">Tier 7</MenuItem>
                </Select>
              </Grid>

              {shape === "Round" && tier !== "Select Tier" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <InputLabel id="diameter">Diameter</InputLabel>
                    <Select
                      labelId="diameter"
                      id="diameter"
                      fullWidth
                      value={diameter}
                      label="Diameter"
                      name="Diameter"
                      onChange={handleDiameterChange}
                    >
                      {(diameterOptions[tier] || []).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel id="height">Height</InputLabel>
                    <Select
                      labelId="height"
                      id="height"
                      fullWidth
                      value={height}
                      label="Height"
                      name="Height"
                      onChange={handleHeightChange}
                    >
                      {tier === "Tier 1" && diameter
                        ? (tier1HeightOptions[diameter] || []).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.text}
                            </MenuItem>
                          ))
                        : heightOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.text}
                            </MenuItem>
                          ))}
                    </Select>
                  </Grid>
                </>
              )}
              {shape === "Square" && (
                <>
                  <Grid item xs={12}>
                    <InputLabel id="squaresize"> Square Size:</InputLabel>
                    <Select
                      labelId="squaresize"
                      id="squaresize"
                      fullWidth
                      value={formData.properties.SquareSize}
                      label="SquareSize"
                      name="SquareSize"
                      onChange={handlePropertyChange}
                    >
                      <MenuItem value="20 x 20 x 8cm">20 x 20 x 8 cm</MenuItem>
                      <MenuItem value="20 x 20 x 10cm">
                        {" "}
                        20 x 20 x 10 cm{" "}
                      </MenuItem>
                      <MenuItem value="24 x 34 x 8cm">24 x 34 x 8 cm</MenuItem>
                      <MenuItem value="30 x 30 x 8cm">30 x 30 x 8 cm</MenuItem>
                      <MenuItem value="30 x 40 x 8cm">30 x 40 x 8 cm</MenuItem>
                      <MenuItem value="30 x 50 x 8cm">30 x 50 x 8 cm</MenuItem>
                      <MenuItem value="40 x 40 x 8cm">40 x 40 x 8 cm</MenuItem>
                      <MenuItem value="40 x 60 x 8cm">40 x 60 x 8 cm</MenuItem>
                      <MenuItem value="50 x 50 x 8cm">50 x 50 x 8 cm</MenuItem>
                      <MenuItem value="60 x 80 x 8cm">60 x 80 x 8 cm</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}

              {shape === "Heart" && (
                <>
                  <Grid item xs={12}>
                    <InputLabel id="squaresize">Heart Size:</InputLabel>
                    <Select
                      labelId="heartsize"
                      id="heartsize"
                      fullWidth
                      value={formData.properties.HeartSize}
                      label="HeartSize"
                      name="HeartSize"
                      onChange={handlePropertyChange}
                    >
                      <MenuItem value="20 x 8cm">20 x 8 cm</MenuItem>
                      <MenuItem value="20 x 10cm">20 x 10 cm</MenuItem>
                      <MenuItem value="20 x 12cm">20 x 12 cm</MenuItem>
                      <MenuItem value="26 x 8cm">26 x 8 cm</MenuItem>
                      <MenuItem value="30 x 8cm">30 x 8 cm</MenuItem>
                      <MenuItem value="35 x 8cm">35 x 8 cm</MenuItem>
                      <MenuItem value="40 x 8cm">40 x 8 cm</MenuItem>
                      <MenuItem value="45 x 8cm">45 x 8 cm</MenuItem>
                      <MenuItem value="50 x 8cm">50 x 8 cm</MenuItem>
                      <MenuItem value="60 x 8cm">60 x 8 cm</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}
              {shape === "Freeform" && (
                <>
                  <Grid item xs={12}>
                    <InputLabel id="free-form">Freiform</InputLabel>
                    <TextField
                      required
                      fullWidth
                      value={formData.properties.FreeformText}
                      onChange={handlePropertyChange}
                      id="FreeformText"
                      label="Free Form"
                      name="FreeformText"
                      placeholder="Enter your custom shape dimensions"
                      autoFocus
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <InputLabel id="free-form"> Füllung</InputLabel>
                <label className="filling">
                  (Max 2):
                  <br />
                  <input
                    type="checkbox"
                    value="None"
                    checked={fillings.includes("None")}
                    onChange={handleFillingsChange}
                  />{" "}
                  None
                  <br />
                  <input
                    type="checkbox"
                    value="Schokolade"
                    checked={fillings.includes("Schokolade")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Schokolade
                  <br />
                  <input
                    type="checkbox"
                    value="Vanille"
                    checked={fillings.includes("Vanille")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Vanille
                  <br />
                  <input
                    type="checkbox"
                    value="Haselnuss"
                    checked={fillings.includes("Haselnuss")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Haselnuss
                  <br />
                  <input
                    type="checkbox"
                    value="Walnuss"
                    checked={fillings.includes("Walnuss")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Walnuss
                  <br />
                  <input
                    type="checkbox"
                    value="Nougat"
                    checked={fillings.includes("Nougat")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Nougat
                  <br />
                  <input
                    type="checkbox"
                    value="Kaffee"
                    checked={fillings.includes("Kaffee")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Kaffee
                  <br />
                  <input
                    type="checkbox"
                    value="Erdbeere"
                    checked={fillings.includes("Erdbeere")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Erdbeere
                  <br />
                  <input
                    type="checkbox"
                    value="Himbeere"
                    checked={fillings.includes("Himbeere")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Himbeere
                  <br />
                  <input
                    type="checkbox"
                    value="Kirsch"
                    checked={fillings.includes("Kirsch")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Kirsch
                  <br />
                  <input
                    type="checkbox"
                    value="Obstfüllung"
                    checked={fillings.includes("Obstfüllung")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Obstfüllung
                  <br />
                  <input
                    type="checkbox"
                    value="Bananencreme"
                    checked={fillings.includes("Bananencreme")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Bananencreme
                  <br />
                  <input
                    type="checkbox"
                    value="Bananenstücke"
                    checked={fillings.includes("Bananenstücke")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Bananenstücke
                  <br />
                  <input
                    type="checkbox"
                    value="Pfirsich Maracuja"
                    checked={fillings.includes("Pfirsich Maracuja")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Pfirsich Maracuja
                  <br />
                  <input
                    type="checkbox"
                    value="Zitrone"
                    checked={fillings.includes("Zitrone")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Zitrone
                  <br />
                  <input
                    type="checkbox"
                    value="Mango"
                    checked={fillings.includes("Mango")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Mango
                  <br />
                  <input
                    type="checkbox"
                    value="Beerenmix"
                    checked={fillings.includes("Beerenmix")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Beerenmix
                  <br />
                  <input
                    type="checkbox"
                    value="Mascarpone"
                    checked={fillings.includes("Mascarpone")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Mascarpone
                  <br />
                  <input
                    type="checkbox"
                    value="Kinderbueno"
                    checked={fillings.includes("Kinderbueno")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Kinderbueno
                  <br />
                  <input
                    type="checkbox"
                    value="Rafaello"
                    checked={fillings.includes("Rafaello")}
                    onChange={handleFillingsChange}
                  />{" "}
                  Rafaello
                </label>
                <br />
                <br />
              </Grid>
              <Grid item xs={12}>
                <label className="cake-coating">
                  Überzug
                  <br />
                  <input
                    type="checkbox"
                    value="Fondant"
                    checked={selectedCoating === "Fondant"}
                    onChange={handleCoatingChange}
                  />{" "}
                  Fondant
                  <br />
                  <input
                    type="checkbox"
                    value="Buttercream"
                    checked={selectedCoating === "Buttercream"}
                    onChange={handleCoatingChange}
                  />{" "}
                  Buttercream
                  <br />
                  <input
                    type="checkbox"
                    value="Turkish Cream"
                    checked={selectedCoating === "Turkish Cream"}
                    onChange={handleCoatingChange}
                  />{" "}
                  Turkish Cream
                  <br />
                  <input
                    type="checkbox"
                    value="Farbe des Überzugs"
                    checked={selectedCoating === "Farbe des Überzugs"}
                    onChange={handleCoatingChange}
                  />{" "}
                  Farbe des Überzugs
                </label>
                <br />
                <br />
              </Grid>
              {showColorInput && (
                <>
                  <Grid item xs={12}>
                    <InputLabel id="colorcoating">
                      Farbe des Überzugs (Color of Coating)
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      name="ColorCoating"
                      value={formData.properties.ColorCoating}
                      onChange={handlePropertyChange}
                      id="colorCoating"
                      placeholder="Enter Farbe des Überzugs"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <InputLabel id="writing">Schrift(Writing)</InputLabel>
                <Select
                  labelId="writing"
                  id="writing"
                  fullWidth
                  value={selectwriting}
                  name="Writing"
                  onChange={handleWritingChange}
                >
                  <MenuItem value="Topper">Topper</MenuItem>
                  <MenuItem value="Cut out">Cut out</MenuItem>
                  <MenuItem value="Handwriting">Handwriting</MenuItem>
                  <MenuItem value="Free text">Free text</MenuItem>
                </Select>
              </Grid>

              {selectwriting === "Cut out" && (
                <Grid item xs={12}>
                  <InputLabel id="cutOption">Cut Out Options</InputLabel>
                  <Select
                    labelId="cutOption"
                    id="cutOption"
                    fullWidth
                    value={cutOption}
                    name="cutOption"
                    onChange={handleCutOptionChange}
                  >
                    <MenuItem value="As in image">As in image</MenuItem>
                    <MenuItem value="On Cake">On Cake</MenuItem>
                    <MenuItem value="Edge">Edge</MenuItem>
                    <MenuItem value="Plate">Plate</MenuItem>
                    <MenuItem value="Sign">Sign</MenuItem>
                  </Select>
                </Grid>
              )}
              {selectwriting === "Handwriting" && (
                <Grid item xs={12}>
                  <InputLabel id="handwritingOption">
                    Handwriting Options
                  </InputLabel>
                  <Select
                    labelId="handwritingOption"
                    id="handwritingOption"
                    fullWidth
                    value={handwritingOption}
                    name="handwritingOption"
                    onChange={handleHandwritingOptionChange}
                  >
                    <MenuItem value="As in image">As in image</MenuItem>
                    <MenuItem value="On Cake">On Cake</MenuItem>
                    <MenuItem value="Edge">Edge</MenuItem>
                    <MenuItem value="Plate">Plate</MenuItem>
                    <MenuItem value="Sign">Sign</MenuItem>
                  </Select>
                </Grid>
              )}
              {selectwriting === "Handwriting" && (
                <Grid item xs={12}>
                  <InputLabel id="handwritingtxt-field">
                    Handwriting Text Field
                  </InputLabel>
                  <TextField
                    required
                    fullWidth
                    name="handwritingtextfield"
                    value={handwritingTextField}
                    onChange={handleHandwritingfreetextOption}
                    id="handwritingtextfield"
                    placeholder="Enter Handwriting text for Option"
                  />
                </Grid>
              )}
              {selectwriting === "Free text" && (
                <Grid item xs={12}>
                  <InputLabel id="freeTextOption">Free Text Field</InputLabel>
                  <TextField
                    required
                    fullWidth
                    name="freeTextOption"
                    value={freeTextOption}
                    onChange={handlefreetextOptionChange}
                    id="freeTextOption"
                    placeholder="Enter the text for Writing"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <InputLabel id="free-form">
                  {" "}
                  Loreum ipsum, context Loreum ipsum, context Loreum ipsum,
                  context Loreum ipsum, context{" "}
                </InputLabel>
              </Grid>
              {/* </Grid> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Draft Order
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item></Grid>
              </Grid>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </DD>
      </Container>
    </ThemeProvider>
  );
}

const DD = styled.section`
  .MuiGrid-root {
    padding: 0px 0 13px 0;
  }
  .main-logo-div {
    text-align: center;
}
  img.logo-img {
    width: 250px;
    height: 77px;
}
`;
