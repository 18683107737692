import React from 'react';
import Form from "./Components/Form/Form"
// import OldForm from "./Components/Form/OldForm"

const CreateDraftOrder = () => {
 
  return (
    <>
      <Form/>
      {/* <OldForm/> */}
    </>
  );
};

export default CreateDraftOrder;
